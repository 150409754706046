body, html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  min-width: 320px !important;
}

svg {
  background-color: $color-theme-primary;
  fill: $color-theme-background-3;
  height: 100%;
}

#preloaderContainer {
  position: fixed;
  background-color: $color-theme-primary;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

#preloaderContainerFigure {
  //@include loading-image-center-page("../img/preloader.gif", 150px);
  @include loading-image-center-page("svg-incorporado", 100px);
}


