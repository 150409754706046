// ****************************************************************************************************************** //
// ******************************************* PRINCIPAIS CORES DO SITE ********************************************* //

$color-theme-primary: #f57c00; //$color-lekkus-pro-primary      usado como cor do tema principal
$color-theme-secundary: #e67e22; //$color-lekkus-pro-secundary  usado para borda dos botões com mouse-hover
$color-theme-dark: #d35400; //$color-lekkus-pro-dark            usado para o preenchimento dos botões com mouse-hover
$color-theme-accent: #ff5722; //$color-lekkus-pro-accent        usado para dar destaque (cor viva dentro do tema)

$color-theme-background-1: #ffffff; //branco                    background usado em fundos e textos opção 1
$color-theme-background-2: #f8f8f8; //cinza claro               background usado em fundos e textos opção 2
$color-theme-background-3: #e7e7e7; //cinza médio               background usado em fundos, linhas divisórias
$color-theme-background-4: #c0c0c0; //cinza escuro              background usado em linhas divisórias)
$color-theme-background-5: rgba(120, 120, 120, 1);
$color-theme-background-6: rgba(70, 70, 70, 0.8);
$color-theme-background-7: rgba(50, 50, 50, 1);
$color-theme-background-8: rgba(30, 30, 30, 0.95);
$color-theme-background-9: rgba(0, 0, 0, 1);

$color-danger: #f44336;
$color-warning: #ffA000;
$color-success: #4caf50;

// ****************************************************************************************************************** //
// ************************************************* BANCO DE CORES ************************************************* //

//cores e tons do logotipo
$color-logo-primary: #0e5a77;
$color-logo-secundary: #2196f3;
$color-logo-dark: #1976d2;
$color-logo-accent: #03a9f4;
$color-logo-tom1: #0c6e8c;
$color-logo-tom2: #2086a5;
$color-logo-tom3: #3ca0bf;
$color-logo-tom4: #7bd4ef;


//cores e tons da versão UNO
$color-lekkus-uno-primary: #1abc9c;
$color-lekkus-uno-secundary: #1abc9c;
$color-lekkus-uno-dark: #1abc9c;
$color-lekkus-uno-accent: #1abc9c;

//cores e tons da versão STANDARD
$color-lekkus-std-primary: #f1c40f;
$color-lekkus-std-secundary: #f1c40f;
$color-lekkus-std-dark: #f1c40f;
$color-lekkus-std-accent: #f1c40f;

//cores e tons da versão PROFESSIONAL
$color-lekkus-pro-primary: #f57c00;
$color-lekkus-pro-secundary: #e67e22; //usado para borda dos botões com mouse-hover
$color-lekkus-pro-dark: #d35400; //usado para o preenchimento dos botões com mouse-hover
$color-lekkus-pro-accent: #ff5722;

//cores e tons da versão ENTERPRISE
$color-lekkus-ent-primary: #c0392b;
$color-lekkus-ent-secundary: #c0392b;
$color-lekkus-ent-dark: #c0392b;
$color-lekkus-ent-accent: #c0392b;


