// Variaveis
//@import "variables/colors";
//@import "variables/fonts";
//@import "variables/medidas";


.tabela-preco-versoes {
  height: 250px;
}

.uno-color {
  top: 0;
  margin-bottom: -21px;
}

/*
.col-xs-3 {
  width: auto;
}
*/

.label-implatancao:after {
  float: left;
  content: 'Implantação';
}

.tabela-preco.tabela-destaque, #tabela-uno, #tabela-standard, #tabela-professional, #tabela-enterprise {
  top: -5px !important;
  margin-bottom: -5px !important;

  &:hover {
    margin-top: 0 !important;
  }
}

i {
  font-size: 2em;
  color: $color-theme-background-4;
  visibility: hidden;
}

#setaUno {
  visibility: visible;
}

.section-a-1 {
  border-bottom: 1px solid $color-theme-background-4;
}

.section-b-1 {
  padding: 60px 0 1px 0;
  border-top: none;
}

.uno-selecionado {
  border-color: $color-lekkus-uno-primary;

  i {
    color: $color-lekkus-uno-primary !important;
  }
}
.std-selecionado {
  border-color: $color-lekkus-std-primary;

  i {
    color: $color-lekkus-std-primary !important;
  }
}
.pro-selecionado {
  border-color: $color-lekkus-pro-primary;

  i {
    color: $color-lekkus-pro-primary !important;
  }
}
.ent-selecionado {
  border-color: $color-lekkus-ent-primary;

  i {
    color: $color-lekkus-ent-primary !important;
  }
}

.rowComboVersoes {
  display: none;
  padding: 0 15px !important;

  ul li:nth-child(1) {
    color: $color-lekkus-uno-primary;
  }

  ul li:nth-child(2) {
    color: $color-lekkus-std-primary;
  }

  ul li:nth-child(3) {
    color: $color-lekkus-pro-primary;
  }

  ul li:nth-child(4) {
    color: $color-lekkus-ent-primary;
  }
}


#comboVersoes {
  height: 60px;
}

.btn-select-arrow {
  padding: 20px 10px !important;
}

.btn-select-value {
  padding: 16px !important;
  font-size: 1.31em !important;
}

.btn-select ul {
  top: 60px !important;

  li {
    padding: 12px !important;
    font-size: 1.31em !important;
  }
}


//*************************************************************************************************************//
//***************************** TAMANHOS ESPECIAIS DE MIDAS (TABLETS E CELULARES) *****************************//
//*************************************************************************************************************//

/*==========  Non-Mobile First Method  ==========*/

/*==========  Medidas personalizadas  ===========*/

@media only screen and (max-width: 1550px) {

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-height: 800px) {

}

@media only screen and (max-height: 700px) {

}

/*==========  Medidas padrão  ===========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .label-implatancao:after {
    content: 'Implant...';
  }
  .section-b-1 {
    padding: 60px 0 26px 0 !important;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .tabela-preco {
    padding-top: 0 !important;
  }
  .tabela-preco-versoes {
    height: 175px !important;
  }

  .rowComboVersoes {
    display: block !important;
  }
}

/* Small Devices, Tablets */
//******************************************************* BREAKPOINT (NAVBAR TOGGLE)
@media only screen and (max-width: 768px) {
  .rowComboVersoes {
    padding: 0 !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

}

/* Custom, iPhone Retina - PORTRAIT */
@media only screen and (max-width: 350px) {

}

/* Custom, iPhone Retina - LANDSCAPE */
@media only screen and (max-height: 320px) {

}




