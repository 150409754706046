/*
 * coloca uma imagem no centro da tela para representar um loading...
 * informar em $imagem, o parametros 'svg-incorporado' caso a imagem seja um svg incorporado no html
 */
@mixin loading-image-center-page($image, $width-height) {
  position: fixed;
  top: 50%;
  left: 50%;

  width: $width-height;
  height: $width-height;

  margin-top: -($width-height/2);
  margin-left: -$width-height;

  @if ($image != 'svg-incorporado') {
    background: $color-theme-primary url($image) no-repeat center;
    margin-left: -($width-height/2);

  }

  transition: opacity 1s 1s linear;
  z-index: 2;
}
