// *************************************************************** //
// TEST ********************************************************** //

main, footer {
  //background-color: #00b3ee;
  //border: solid blue 3px;

  section {
    //background-color: #ebebeb;
    //border: dotted orangered 3px;
    //height: 700px;
  }

  div {
    //border: dotted yellow 3px;
  }
}

// *************************************************************** //
// FULL SCREEN *************************************************** //

#homeFullScreen {
  min-height: 600px;
  height: 100vh;
  width: 100%;
  background: url('../img/home-fullscreen.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
  color: $color-logo-primary;
  font-family: 'Quicksand', sans-serif;
  font-weight: 300;

  .id-home-logo {
    width: 50%;
    max-width: 600px;
    animation-delay: 0.7s;
    animation-duration: 0.2s;
  }

  h2 {
    font-size: 35px;
    animation-delay: 1s;
    animation-duration: 0.2s;
    margin-bottom: 80px;
  }

  .id-btn-pri {
    animation-delay: 1.3s;
  }

  .id-btn-std {
    animation-delay: 1.5s;
    animation-duration: 1s;
  }

  #homeFullScreenContent {
    margin-top: 10%;
  }
}

[class^='id-btn'], [class*=' id-btn'] {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.id-btn-std, .id-btn-std::selection {
  text-decoration: none;
  border: 1px solid $color-theme-primary;
  color: $color-theme-primary !important;
  background: transparent;
}

.id-btn-pri, .id-btn-pri::selection {
  border: 1px solid $color-theme-primary;
  color: $color-theme-background-3 !important;
  background-color: $color-theme-primary;
}

.id-btn-pri:hover, .id-btn-pri:focus, .id-btn-std:hover, .id-btn-std:focus {
  border: 1px solid $color-logo-primary;
  color: $color-logo-primary !important;
  background: rgba(204, 204, 204, 0);
}

#id-home-tarja-telefone {
  background: $color-theme-primary;
  color: $color-theme-background-3;
  visibility: hidden;
  height: 40px !important;
  padding-top: 10px;

  a {
    text-decoration: none;
    color: inherit;
  }
}

// *************************************************************** //
// NAVBAR ******************************************************** //

#navBarRoot {
  width: 100%;
  z-index: 900;
  position: relative;
  top: 0;
  color: $color-theme-primary;

  #navBar {
    //definir nesta variável a altura fixa da navBar
    $nav-bar-altura: 60px !important;
    min-width: $largura-minima;

    height: $nav-bar-altura;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: $color-theme-primary !important;
    //z-index: 999;

    #navBarContainer {
      height: $nav-bar-altura;
    }

    #navBarHeader {
      height: $nav-bar-altura;

      button {
        border: none;
        background: transparent !important;
      }

      //efeito do ícone toggle se transformando em X
      .icon-bar {
        width: 22px;
        transition: all 0.2s;
        background-color: $color-theme-background-2 !important;
      }
      .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }
      .middle-bar {
        opacity: 0;
      }
      .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
      .collapsed .top-bar {
        transform: rotate(0deg);
      }
      .collapsed .middle-bar {
        opacity: 1;
      }
      .collapsed .bottom-bar {
        transform: rotate(0deg);
      }
      //fim do efeito do ícone toggle se transformando em X

    }

    #navBarToggle {
      background-color: $color-theme-primary;
    }

    #navBarBrand {
      height: $nav-bar-altura;
      padding: 10px !important;

      img {
        width: 170px;
        cursor: pointer;
      }
    }

    ul li a {
      height: $nav-bar-altura;
      padding: 20px;
      color: $color-theme-background-2;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-size: 1.1em;
      text-align: center;
    }

    ul li a:hover, ul li.active a {
      color: $color-logo-primary;
      background-color: transparent;
    }

    button {
      margin: 13px 15px 13px 0;
    }

  }

}

.nav-bar-root-fixed {
  position: fixed !important;
}

.uno-color strong {
  color: $color-lekkus-uno-primary;
}

.standard-color strong {
  color: $color-lekkus-std-primary;
}

.professional-color strong {
  color: $color-lekkus-pro-primary;
}

.enterprise-color strong {
  color: $color-lekkus-ent-primary;
}

#quemsomos {
  min-height: 700px;

  figure {
    min-width: 262px;
    text-align: center;
    //border: 1px solid red;
    font-size: 1.1em;
    color: $color-theme-background-4;
    margin: 0 auto;
  }

  img {
    border: 2px solid $color-theme-background-5;
    margin: 50px 30px 10px;
    width: 200px;
  }

  .apelido {
    font-family: 'lord-of-the-rings';
    font-size: 1.5em;
  }

  .col-centered {
    float: none;
    margin: 0 auto;
  }
}

#contato {
  padding: 0 !important;

  div {
    padding: 0;
  }

  .icone-social {
    padding: 45px 30px;
    border-right: 1px solid $color-theme-background-6;
    border-bottom: 1px solid $color-theme-background-6;
  }

  hr {
    border-top: 1px solid $color-theme-background-6;
  }

  img {
    height: 50px;
    margin: 50px 0;
  }

  .painel-endereco {
    padding: 50px 100px;
    border-right: 1px solid $color-theme-background-6;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1em;
    color: $color-theme-background-5;
    height: 480px;
    //min-width: 550px !important;

    .campo {
      height: 55px;
      padding-top: 18px;
      display: flex;

      div.label {
        width: 130px;
      }
      div.icone {
        text-align: center;
        width: 80px;

        img {
          width: 15px;
          height: 15px;
          margin: 0 !important;
        }
      }
      div.dado {
        //font-weight: 400;
        font-size: 0.9em;
        letter-spacing: 2px;
        color: $color-theme-background-4 !important;
        width: 100%;
      }
    }

    .endereco {
      height: 85px;
    }
  }

  .painel-contato {
    color: $color-theme-background-5;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    padding: 40px 100px;

    h5 {
      color: $color-theme-background-4;
      text-align: center;
      font-size: 1.5em;
      font-weight: 700;
      margin-bottom: 20px;
    }
    form {
      //padding: 70px 100px;
    }
  }
}

#quemsomos {
  img {
    width: 160px;
  }
}

#copyright {
  padding: 10px 0 20px 0;
  background-color: $color-theme-primary;
  color: $color-theme-background-1;
  font-family: 'Lato', sans-serif;
  font-size: 0.7em;
  font-weight: 300;
  text-align: center;
  letter-spacing: 1.5px;
}

.whatsapp {
  visibility: hidden;
}

//alterando a cor do tooltip do bootstrap
//.tooltip-arrow,
//.cm-tooltip + .tooltip > .tooltip-inner {
//  background-color: #f00;
//}

// *************************************************************** //
// SESSÕES ******************************************************* //

.section-a {
  background: $color-theme-background-2;
  padding: 90px 0;
}

.section-b {
  background: $color-theme-background-1;
  border-top: 1px solid $color-theme-background-4;
  border-bottom: 1px solid $color-theme-background-4;
  padding: 90px 0;
}

.section-footer {
  color: $color-theme-background-3;
  background: $color-theme-background-8;
  padding: 90px 0;

  a, a:visited {
    color: $color-theme-background-4;
    text-decoration: none;
  }

  a:hover {
    color: $color-theme-primary;
    text-decoration: none;
  }
}

// *************************************************************** //
// EFEITO PARALLAX *********************************************** //

.parallax {
  position: relative;
  background: url(../img/equipe-infodesk.jpg) fixed center;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
}

.parallax:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  //background: rgba(255, 255, 255, 0.9);
  background: rgba(0, 0, 0, 0.8);
}

.parallax-content {
  z-index: 1;
  //height: 400px;
  padding: 90px 0 30px;
  position: relative;

  h1 {
    padding-top: 50px;
  }
}

// *************************************************************** //
// ELEMENTOS E CLASSES COMUNS ************************************ //



//títulos
h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 2.5em;
  text-align: center;
}

//sub-títulos em destaques
.texto-destaque {
  line-height: 34px;
  padding: 17px 0;
  font-weight: 300;
  font-size: 1.7em;
}

//textos comuns
p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2em;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 20px;
}

#painelEsquerdo {
  padding-right: 30px;
  padding-left: 30px;
}

#painelDireito {
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 20px;

  img {
    padding-top: 80px;
  }
}

.painelUnico {
  padding-left: 30px;
  padding-right: 30px;
}

.btn-primary, .btn-primary:focus {
  background-color: $color-theme-primary;
  border-color: $color-theme-secundary;
  border-radius: 5px;
}

.btn-primary:hover {
  background-color: $color-theme-dark;
  border-color: $color-theme-secundary;
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover,
.btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover {
  background-color: $color-theme-secundary;
  border-color: $color-theme-dark;
}

.cm-price-cinza {
  color: lightgray !important;
}

.cm-price-topborder {
  border-top: 1px solid;
  border-color: lightgray;
}

.cm-table-sn, .cm-icon-table {
  width: 20px;
}

.tabela-preco {
  padding-top: 50px;
  height: 1470px;
  margin-bottom: 0;
}


.modulos, .fiscal, .recursos, .instalacao {
  text-align: left;
}

li:not(class) {
  text-align: left;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}


//*************************************************************************************************************//
//***************************** TAMANHOS ESPECIAIS DE MIDAS (TABLETS E CELULARES) *****************************//
//*************************************************************************************************************//

/*==========  Non-Mobile First Method  ==========*/

/*==========  Medidas personalizadas  ===========*/

@media only screen and (max-width: 1550px) {
  .painel-endereco {
    padding: 50px 60px !important;
  }
  .painel-contato {
    padding: 40px 60px 40px 50px !important;
  }
}

@media only screen and (max-width: 1300px) {
  .painel-endereco {
    padding: 30px 20px !important;
  }
  .painel-contato {
    padding: 20px 40px 20px 30px !important;
  }
}

@media only screen and (max-height: 800px) {
  #contato {
    padding-top: 60px !important;

    .icone-social {
      border-top: 1px solid $color-theme-background-6;
    }
  }
}

@media only screen and (max-height: 700px) {
  #homeFullScreenContent {
    margin-top: 0 !important;
  }
}

/*==========  Medidas padrão  ===========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {

  .icone-social {
    padding: 0 !important;
  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  #homeFullScreen {
    .id-home-logo {
      margin-top: 0;
      width: 50%;
    }

    h2 {
      font-size: 1.8em;
    }
  }

  .whatsapp {
    visibility: visible !important;
  }

  .video-iframe {
    height: 450px !important;
  }

}

/* Small Devices, Tablets */
//******************************************************* BREAKPOINT (NAVBAR TOGGLE)
@media only screen and (max-width: 768px) {

  //títulos
  h1 {
    font-size: 2em;
  }

  //sub-títulos em destaques
  .texto-destaque {
    text-align: center;
    line-height: 28px;
    padding: 10px 0;
    font-size: 1.3em;
  }

  //textos comuns
  p {
    font-size: 1em;
    line-height: 22px;
  }

  .section-a, .section-b, .section-footer {
    padding: 90px 0 30px 0;
  }

  .sem-navbar {
    padding: 30px 0 30px 0 !important;
  }

  #painelDireito {
    text-align: center;

    img {
      padding-top: 0 !important;
      width: 60%;
      display: inline;
    }
  }

  #navBar ul li a {
    height: 40px !important;
    padding: 10px;
  }

  #homeFullScreen {
    .id-home-logo {
      margin-top: 50px;
      width: 60%;
    }

    h2 {
      margin-bottom: 50px;
    }
  }


  #quemsomos {
    background: url(../img/code.jpg) fixed center;
    background-size: cover;
  }

  .painel-endereco {
    padding: 40px 20px !important;
    height: 420px !important;
  }

  .painel-contato {
    padding: 40px 50px !important;
  }

  .tabela-preco {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  //pagina 02 - videos o que é erp

  .video {
    margin: 0 !important;
  }
  .cm-panel .panel-body {
    padding: 0 !important;
  }

  .video-iframe {
    height: 290px !important;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  #homeFullScreen {
    background-image: url('../img/home-fullscreen-mais-branco.jpg');

    h2 {
      font-size: 1.2em;
    }
  }

  #id-home-tarja-telefone {
    visibility: visible;
    font-weight: 900;
  }

  div .label {
    display: none;
  }

  #quemsomos {
    padding: 0 40px !important;

    img {
      width: 130px;
    }
  }

  .video-iframe {
    height: 230px !important;
  }

}

/* Custom, iPhone Retina - PORTRAIT */
@media only screen and (max-width: 350px) {
  #homeFullScreen {
    .id-home-logo {
      margin-top: 30px;
    }

    h2 {
      margin-bottom: 50px;
      font-weight: 400;
    }

    .id-btn-std {
      margin-top: 30px;
    }
  }

  .login-container {
    margin-top: -150px;
  }

  .video-iframe {
    height: 180px !important;
  }

}

/* Custom, iPhone Retina - LANDSCAPE */
@media only screen and (max-height: 320px) {
  #homeFullScreen {
    .id-home-logo {
      width: 40%;
      margin-top: 0;
    }

    h2 {
      margin-bottom: 30px;
      font-size: 1.2em;
      font-weight: 400;
    }
  }
}





