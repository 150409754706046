//*************************************************************************************************************//
//******************************* CSS PAGINA saiba-mais-sobre-sistema-erp.html ********************************//
//*************************************************************************************************************//

#Pagina2 {
  margin-top: 60px;
}

.video-titulo {
  border: none !important;
  border-radius: 0 !important;

  h1 {
    padding: 40px 20px;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 2.5em;
    text-align: center;
    color: white;
  }

  a:visited, a:hover, a {
    text-decoration: none;
  }
}


@mixin defineCor($versao, $titulo) {

  h1 {
    //color: $color-theme-background-2 !important;
    color: $color-theme-background-7 !important;
  }

  $transparenciaTitulo: 0.8;
  $transparenciaConteudo: ($transparenciaTitulo - 0.1);

  background: $color-theme-background-3;
  //background: rgba(26, 188, 156, if($titulo, $transparenciaTitulo, $transparenciaConteudo)) !important;
  //border-bottom: 1px solid rgb(26,188,156) !important;

  margin: 10px;
  border-radius: 10px !important;


  /*
  $transparenciaTitulo: 0.8;
  $transparenciaConteudo: ($transparenciaTitulo - 0.1);

  @if ($versao == 'UNO') {
      //cor do uno
      background: rgba(26, 188, 156, if($titulo, $transparenciaTitulo, $transparenciaConteudo)) !important;

  } @else if ($versao == 'STD') {
    //cor do standard
    background: rgba(241, 196, 15, if($titulo, $transparenciaTitulo, $transparenciaConteudo)) !important;

  } @else if ($versao == 'PRO') {
    //cor do professional
    background: rgba(245, 124, 0, if($titulo, $transparenciaTitulo, $transparenciaConteudo)) !important;

  } @else if ($versao == 'ENT') {
    //cor do enterprise
    background: rgba(192, 57, 43, if($titulo, $transparenciaTitulo, $transparenciaConteudo)) !important;
  }
  */

}

#video01Titulo {
  @include defineCor('UNO', true);
}
#video01Conteudo {
  @include defineCor('UNO', false);
}

#video02Titulo {
  @include defineCor('STD', true);
}
#video02Conteudo {
  @include defineCor('STD', false);
}

#video03Titulo {
  @include defineCor('PRO', true);
}
#video03Conteudo {
  @include defineCor('PRO', false);
}

#video04Titulo {
  @include defineCor('ENT', true);
}
#video04Conteudo {
  @include defineCor('ENT', false);
}

#video05Titulo {
  @include defineCor('UNO', true);
}
#video05Conteudo {
  @include defineCor('UNO', false);
}

#video06Titulo {
  @include defineCor('STD', true);
}
#video06Conteudo {
  @include defineCor('STD', false);
}

#video07Titulo {
  @include defineCor('PRO', true);
}
#video07Conteudo {
  @include defineCor('PRO', false);
}

#video08Titulo {
  @include defineCor('ENT', true);
}
#video08Conteudo {
  @include defineCor('ENT', false);
}

#video09Titulo {
  @include defineCor('UNO', true);
}
#video09Conteudo {
  @include defineCor('UNO', false);
}



.video {
  border: none !important;
}

.video-iframe {
  width: 100%;
  max-width: 820px;
  height: 450px;
}

//sobrescreve css do bootstrap referente aos paineis collapses.
.cm-panel {
  border: none !important;
  box-shadow: none;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.cm-container-fluid, .cm-col {
  padding: 0 !important;
}


#formularioDuvidas {

  margin: 0 20px 40px;
  padding: 30px 20px 10px;
  //background: rgba(26, 188, 156, 0.2) !important;
  background: rgba(230, 126, 34, 0.2) !important;
  border-radius: 10px;


  .form-group {
    text-align: left !important;
    color: $color-theme-background-6 !important;
  }

  button {
    text-align: center !important;
    margin: 20px;
  }

}




//*************************************************************************************************************//
//***************************** TAMANHOS ESPECIAIS DE MIDAS (TABLETS E CELULARES) *****************************//
//*************************************************************************************************************//

/*==========  Non-Mobile First Method  ==========*/

/*==========  Medidas personalizadas  ===========*/

@media only screen and (max-width: 1550px) {

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-height: 800px) {

}

@media only screen and (max-height: 700px) {

}

/*==========  Medidas padrão  ===========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

}

/* Small Devices, Tablets */
//******************************************************* BREAKPOINT (NAVBAR TOGGLE)
@media only screen and (max-width: 768px) {

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

}

/* Custom, iPhone Retina - PORTRAIT */
@media only screen and (max-width: 350px) {

}

/* Custom, iPhone Retina - LANDSCAPE */
@media only screen and (max-height: 320px) {

}




