
.login-container {
  position: fixed;
  width: 300px;
  margin: 80px auto;
  padding: 20px 40px 40px;
  text-align: center;
  background: #fff;
  border: 1px solid #ccc;

  z-index: 3;
  display: none;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -150px;
}

.login-container::before, .login-container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 3.5px;
  left: 0;
  background: #fff;
  z-index: -1;
  transform: rotateZ(4deg);
  border: 1px solid #ccc;
}

.login-container::after {
  top: 5px;
  z-index: -2;
  transform: rotateZ(-2deg);
}

#login-avatar {
  width: 100px;
  height: 100px;
  margin: 10px auto 5px;
  border-radius: 100%;
  border: 2px solid $color-theme-dark;
  background-size: cover;
  background: center no-repeat;
  display: none;
}

#login-avatar-nome {
  color: $color-theme-primary;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  display: none;
}

#login-logo img {
  height: 90px;
  margin-bottom: 10px;
}

.id-form-login input {
  width: 100%;
  padding: 10px;
  text-align: center;
  height: 40px;
  border: 1px solid #ccc;;
  background: #fafafa;
  transition: 0.2s ease-in-out;
}

.id-form-login input:focus {
  outline: 0;
  background: #eee;
}

.id-form-login input[type="text"] {
  border-radius: 5px 5px 0 0;
}

.id-form-login input[type="password"] {
  border-radius: 0 0 5px 5px;
  border-top: 0;
}

.id-form-login button.login {
  margin-top: 10px;
  padding: 10px 20px;
}

#btnCancelarlogin {
  margin-top: 0;
}

//.ocultaCampos {
//  height: 0;
//  padding: 0;
//  margin: 0;
//  opacity: 0;
//}

//'-----'

#lblLoginMensagem {
  position: absolute;
  width: 300px;
  top: -75px;
  left: 0;
  color: #fff;
}

#lblLoginMensagem.alert-success {
  background: $color-success;
}

#lblLoginMensagem.alert-warning {
  background: $color-warning;
}

#lblLoginMensagem.alert-danger {
  //color: $color-theme-background-3;
  background: $color-danger;
  //background: rgb(228, 105, 105);
}


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

