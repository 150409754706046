/*
* https://fonts.google.com/specimen/Quicksand?selection.family=Quicksand
* font-family: 'Quicksand', sans-serif;
*/
@import 'https://fonts.googleapis.com/css?family=Quicksand:300,400,700';

/*
* https://fonts.google.com/specimen/Lato
* -- Lato
* font-family: 'Lato', sans-serif;
* 300 = Subtítulo: 'Sistema de Gestão Empresarial'
* 400 = Menu Navbar
*
* -- Open Sans
* font-family: 'Open Sans', sans-serif;
* 300 = Parágrafos em destaques
* 400 = Textos diversos (maçantes)
*/
@import 'https://fonts.googleapis.com/css?family=Lato:300,400|Open+Sans:300,400&subset=cyrillic';


/*
* http://www.1001fonts.com/ringbearer-font.html
* font-family: Ringbearer Font
*/
@font-face {
  font-family: 'lord-of-the-rings';
  src: url('../fonts/lotr.ttf');
}


/*
@font-face {
  font-family: 'lato-bold';
  src: url('/public/assets/fonts/Lato/Lato-Bold.ttf');
}
@font-face {
  font-family: 'lato-light';
  src: url('/public/assets/fonts/Lato/Lato-Light.ttf');
}

@font-face {
  font-family: 'lato-menu';
  src: url('/public/assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: 400;
}
*/


