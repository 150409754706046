.btn-select {
  position: relative;
  padding: 0;
  min-width: 236px;
  width: 100%;
  border-radius: 0;
  margin-bottom: 20px;
}

.btn-select .btn-select-value {
  padding: 6px 12px;
  display: block;
  position: absolute;
  left: 0;
  right: 34px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
}

.btn-select .btn-select-arrow {
  float: right;
  line-height: 20px;
  padding: 6px 10px;
  top: 0;
}

.btn-select ul {
  display: none;
  background-color: white;
  color: black;
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: none !important;
  position: absolute;
  left: -1px;
  right: -1px;
  top: 33px;
  z-index: 999;
}

.btn-select ul li {
  padding: 3px 6px;
  text-align: left;
}

.btn-select ul li:hover {
  background-color: #f4f4f4;
}

.btn-select ul li.selected {
  color: white;
}

/* Default Start */
.btn-select.btn-default:hover, .btn-select.btn-default:active, .btn-select.btn-default.active {
  border-color: #ccc;
}

.btn-select.btn-default ul li.selected {
  background-color: #ccc;
}

.btn-select.btn-default ul, .btn-select.btn-default .btn-select-value {
  background-color: white;
  border: #ccc 1px solid;
}

.btn-select.btn-default:hover, .btn-select.btn-default.active {
  background-color: #e6e6e6;
}
/* Default End */

/* Primary Start */
.btn-select.btn-primary:hover, .btn-select.btn-primary:active, .btn-select.btn-primary.active {
  border-color: #286090;
}

.btn-select.btn-primary ul li.selected {
  background-color: #2e6da4;
  color: white;
}

.btn-select.btn-primary ul {
  border: #2e6da4 1px solid;
}

.btn-select.btn-primary .btn-select-value {
  background-color: #428bca;
  border: #2e6da4 1px solid;
}

.btn-select.btn-primary:hover, .btn-select.btn-primary.active {
  background-color: #286090;
}
/* Primary End */



/* info Start */
.btn-select.btn-info:hover, .btn-select.btn-info:active, .btn-select.btn-info.active {
  border-color: #46b8da;
}

.btn-select.btn-info ul li.selected {
  background-color: #46b8da;
  color: white;
}

.btn-select.btn-info ul {
  border: #46b8da 1px solid;
}

.btn-select.btn-info .btn-select-value {
  background-color: #5bc0de;
  border: #46b8da 1px solid;
}

.btn-select.btn-info:hover, .btn-select.btn-info.active {
  background-color: #269abc;
}
/* info End */

/* warning Start */
.btn-select.btn-warning:hover, .btn-select.btn-warning:active, .btn-select.btn-warning.active {
  border-color: #eea236;
}

.btn-select.btn-warning ul li.selected {
  background-color: #eea236;
  color: white;
}

.btn-select.btn-warning ul {
  border: #eea236 1px solid;
}

.btn-select.btn-warning .btn-select-value {
  background-color: #f0ad4e;
  border: #eea236 1px solid;
}

.btn-select.btn-warning:hover, .btn-select.btn-warning.active {
  background-color: #d58512;
}
/* warning End */

/* danger Start */
.btn-select.btn-danger:hover, .btn-select.btn-danger:active, .btn-select.btn-danger.active {
  border-color: #d43f3a;
}

.btn-select.btn-danger ul li.selected {
  background-color: #d43f3a;
  color: white;
}

.btn-select.btn-danger ul {
  border: #d43f3a 1px solid;
}

.btn-select.btn-danger .btn-select-value {
  background-color: #d9534f;
  border: #d43f3a 1px solid;
}

.btn-select.btn-danger:hover, .btn-select.btn-danger.active {
  background-color: #c9302c;
}
/* danger End */

.btn-select.btn-select-light .btn-select-value {
  background-color: white;
  color: black;
}


/* Success Start */
.btn-select.btn-success:hover, .btn-select.btn-success:active, .btn-select.btn-success.active {
  border-color: #4cae4c;
}

.btn-select.btn-success ul li.selected {
  background-color: #4cae4c;
  color: white;
}

.btn-select.btn-success ul {
  border: #4cae4c 1px solid;
}

.btn-select.btn-success .btn-select-value {
  background-color: #5cb85c;
  border: #4cae4c 1px solid;
}

.btn-select.btn-success:hover, .btn-select.btn-success.active {
  background-color: #449d44;
}


/* Success End */



/* btn-uno inicio */
.btn-select.btn-uno {
  background-color: $color-lekkus-uno-primary;
  border: $color-lekkus-uno-primary 1px solid;
  color: $color-theme-background-1;

  .btn-select-value {
    color: $color-lekkus-uno-primary;
  }
}
.btn-select.btn-uno:hover, .btn-select.btn-uno:active, .btn-select.btn-uno.active {
  border-color: $color-lekkus-uno-primary;
}
.btn-select.btn-uno ul li.selected {
  background-color: $color-lekkus-uno-primary;
  color: $color-theme-background-1;
}
.btn-select.btn-uno ul {
  border: $color-lekkus-uno-primary 1px solid;
}
.btn-select.btn-uno .btn-select-value {
  background-color: $color-theme-background-1;
  border: $color-lekkus-uno-primary 1px solid;
}
.btn-select.btn-uno:hover, .btn-select.btn-uno.active {
  background-color: $color-lekkus-uno-dark;
}
/* btn-uno fim */



/* btn-std inicio */
.btn-select.btn-std {
  background-color: $color-lekkus-std-primary;
  border: $color-lekkus-std-primary 1px solid;
  color: $color-theme-background-1;

  .btn-select-value {
    color: $color-lekkus-std-primary;
  }

}
.btn-select.btn-std:hover, .btn-select.btn-std:active, .btn-select.btn-std.active {
  border-color: $color-lekkus-std-primary;
}
.btn-select.btn-std ul li.selected {
  background-color: $color-lekkus-std-primary;
  color: $color-theme-background-1;
}
.btn-select.btn-std ul {
  border: $color-lekkus-std-primary 1px solid;
}
.btn-select.btn-std .btn-select-value {
  background-color: $color-theme-background-1;
  border: $color-lekkus-std-primary 1px solid;
}
.btn-select.btn-std:hover, .btn-select.btn-std.active {
  background-color: $color-lekkus-std-dark;
}
/* btn-std fim */



/* btn-pro inicio */
.btn-select.btn-pro {
  background-color: $color-lekkus-pro-primary;
  border: $color-lekkus-pro-primary 1px solid;
  color: $color-theme-background-1;

  .btn-select-value {
    color: $color-lekkus-pro-primary;
  }

}
.btn-select.btn-pro:hover, .btn-select.btn-pro:active, .btn-select.btn-pro.active {
  border-color: $color-lekkus-pro-primary;
}
.btn-select.btn-pro ul li.selected {
  background-color: $color-lekkus-pro-primary;
  color: $color-theme-background-1;
}
.btn-select.btn-pro ul {
  border: $color-lekkus-pro-primary 1px solid;
}
.btn-select.btn-pro .btn-select-value {
  background-color: $color-theme-background-1;
  border: $color-lekkus-pro-primary 1px solid;
}
.btn-select.btn-pro:hover, .btn-select.btn-pro.active {
  background-color: $color-lekkus-pro-dark;
}
/* btn-pro fim */



/* btn-ent inicio */
.btn-select.btn-ent {
  background-color: $color-lekkus-ent-primary;
  border: $color-lekkus-ent-primary 1px solid;
  color: $color-theme-background-1;

  .btn-select-value {
    color: $color-lekkus-ent-primary;
  }

}
.btn-select.btn-ent:hover, .btn-select.btn-ent:active, .btn-select.btn-ent.active {
  border-color: $color-lekkus-ent-primary;
}
.btn-select.btn-ent ul li.selected {
  background-color: $color-lekkus-ent-primary;
  color: $color-theme-background-1;
}
.btn-select.btn-ent ul {
  border: $color-lekkus-ent-primary 1px solid;
}
.btn-select.btn-ent .btn-select-value {
  background-color: $color-theme-background-1;
  border: $color-lekkus-ent-primary 1px solid;
}
.btn-select.btn-ent:hover, .btn-select.btn-ent.active {
  background-color: $color-lekkus-ent-dark;
}
/* btn-ent fim */
