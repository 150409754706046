// Definicao das propriedades das tabelas
[class*="col-"].span-centralizado {
  margin-left: auto;
  margin-right: auto;
  float: none;
}

.tabela-preco {
  font-family: 'Open Sans', sans-serif;
  position: relative;
}
.tabela-preco .tabela-titulo,
.tabela-preco .tabela-mensalidade,
.tabela-preco .tabela-subtitulo,
.tabela-preco .button {
  border-bottom: none !important;
}
.tabela-preco .tabela-titulo {
  color: #ecf0f1;
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  text-align: center;
}
.tabela-preco .tabela-subtitulo {
  color: #ecf0f1;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tabela-preco .tabela-mensalidade {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  background-color: #ecf0f1;
  padding: 15px;
  text-align: center;
}
.tabela-preco .tabela-mensalidade .tabela-simbolo-real {
  position: relative;
  top: -17px;
  color: #77939a;
}
.tabela-preco .tabela-mensalidade strong {
  font-size: 44px;
  line-height: 44px;
  display: inline-block;
}
.tabela-preco .tabela-mensalidade sup {
  position: relative;
  top: -20px;
}
.tabela-preco .tabela-mensalidade em {
  position: relative;
  bottom: -2px;
  font-size: 14px;
  font-weight: normal;
  color: #a3b6bb;
}
.tabela-preco .button {
  padding: 15px;
  text-align: center;
}
.tabela-preco .button .btn {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: #ecf0f1;
  background-color: #ecf0f1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 5px;
}
.tabela-preco .button .btn:hover {
  background-color: #dde4e6;
  color: #fbfcfc;
}
.tabela-preco .button.colored {
  position: relative;
}
.tabela-preco .button.colored:after {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.tabela-preco .button.colored:after {
  border-width: 5px;
  left: 50%;
  margin-left: -5px;
}
.tabela-preco ul {
  position: relative;
  list-style: none;
  margin: 0;
  z-index: 1;
  padding: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.tabela-preco ul li {
  color: #77939a;
  background-color: #ffffff;
  padding: 10px;
  border-bottom: 1px solid #ecf0f1;
}
.tabela-preco.rounded ul {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.tabela-preco.rounded .tabela-titulo {
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
}
.tabela-preco.rounded .button {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  border-bottom-right-radius: 5px;
}
.tabela-preco.rounded .button .btn {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.tabela-preco.bordered ul {
  border: 5px solid #f5f7f7;
}
.tabela-preco.tabela-destaque {
  top: -21px;
  margin-bottom: -21px;
}
.tabela-preco.tabela-destaque .tabela-subtitulo {
  font-family: 'Open Sans', sans-serif;
  text-shadow: none;
}
.tabela-preco.tabela-destaque .tabela-mensalidade .tabela-simbolo-real,
.tabela-preco.tabela-destaque .tabela-mensalidade strong,
.tabela-preco.tabela-destaque .tabela-mensalidade sup,
.tabela-preco.tabela-destaque .tabela-mensalidade em {
  color: #ecf0f1;
}
.tabela-preco.plain li {
  color: #77939a;
  background-color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.tabela-preco.plain li.button {
  border-bottom: none;
}
.tabela-preco.plain .alt li {
  background-color: #f8f9fa;
  border-bottom: 1px solid #f8f9fa;
}
.tabela-preco.plain .alt .tabela-mensalidade .tabela-simbolo-real,
.tabela-preco.plain .alt .tabela-mensalidade strong,
.tabela-preco.plain .alt .tabela-mensalidade sup,
.tabela-preco.plain .alt .tabela-mensalidade em {
  color: #77939a;
}
.tabela-preco.plain .alt-dark li {
  color: #77939a;
  background-color: #2c3e50;
  border-bottom: 1px solid #2c3e50;
}
.tabela-preco.plain.tabela-destaque .tabela-mensalidade {
  background-color: #ffffff;
}
.tabela-preco.plain.tabela-destaque .tabela-mensalidade .tabela-simbolo-real,
.tabela-preco.plain.tabela-destaque .tabela-mensalidade strong,
.tabela-preco.plain.tabela-destaque .tabela-mensalidade sup {
  color: #77939a;
}
.tabela-preco.plain.tabela-destaque .tabela-mensalidade em {
  color: #94aab0;
}
.tabela-preco.plain.tabela-destaque .tabela-subtitulo {
  background-color: #ffffff;
}
.tabela-preco.plain.tabela-destaque .alt .tabela-mensalidade {
  color: #77939a;
  background-color: #f8f9fa;
}
.tabela-preco.plain.tabela-destaque .alt .tabela-subtitulo {
  background-color: #f8f9fa;
}
.tabela-preco.plain.tabela-destaque .alt-dark .tabela-mensalidade {
  color: #77939a;
  background-color: #2c3e50;
}
.tabela-preco.plain.tabela-destaque .alt-dark .tabela-subtitulo {
  background-color: #2c3e50;
}
.tabela-preco.animacao-hover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.tabela-preco .alt li {
  background-color: #f8f9fa;
}
.tabela-preco .alt .tabela-mensalidade {
  background-color: #ecf0f1;
}
.tabela-preco .alt-dark {
  color: #ecf0f1;
}
.tabela-preco .alt-dark li {
  background-color: #2c3e50;
  border-bottom: 1px solid #273646;
}
.tabela-preco .alt-dark .tabela-mensalidade {
  background-color: #273646;
}




/* Definição de cores para a versão UNO */
.uno-color .tabela-titulo,
.uno-color .alt .tabela-titulo,
.uno-color .alt-dark .tabela-titulo {
  background-color: $color-lekkus-uno-primary;
}
.uno-color .button .btn {
  background-color: $color-lekkus-uno-primary;
  border: 1px solid $color-lekkus-uno-primary;
}
.uno-color .button .btn:hover {
  background-color: #17a689;
}
.uno-color .button.btn-hover-alt .btn:hover {
  color: $color-lekkus-uno-primary;
  background-color: #ffffff;
}
.uno-color .button.colored {
  background-color: $color-lekkus-uno-primary;
}
.uno-color .button.colored:after {
  border-color: transparent;
  border-bottom-color: $color-lekkus-uno-primary;
}
.uno-color.tabela-destaque ul {
  border: 5px solid $color-lekkus-uno-primary;
}
.uno-color.tabela-destaque .tabela-mensalidade,
.uno-color.tabela-destaque .tabela-subtitulo {
  background-color: #18af91;
}
.uno-color.animacao-hover:hover {
  margin-top: -5px;
}
.uno-color.animacao-hover:hover.bordered ul {
  border: 5px solid $color-lekkus-uno-primary;
}
.uno-color.animacao-hover.tabela-destaque:hover {
  margin-top: -5px;
}



/* Definição de cores para a versão STANDARD */
.standard-color .tabela-titulo,
.standard-color .alt .tabela-titulo,
.standard-color .alt-dark .tabela-titulo {
  background-color: #f1c40f;
}
.standard-color .button .btn {
  background-color: #f1c40f;
  border: 1px solid #f1c40f;
}
.standard-color .button .btn:hover {
  background-color: #dab10d;
}
.standard-color .button.btn-hover-alt .btn:hover {
  color: #f1c40f;
  background-color: #ffffff;
}
.standard-color .button.colored {
  background-color: #f1c40f;
}
.standard-color .button.colored:after {
  border-color: transparent;
  border-bottom-color: #f1c40f;
}
.standard-color.tabela-destaque ul {
  border: 5px solid #f1c40f;
}
.standard-color.tabela-destaque .tabela-mensalidade,
.standard-color.tabela-destaque .tabela-subtitulo {
  background-color: #e3b90d;
}
.standard-color.animacao-hover:hover {
  margin-top: -5px;
}
.standard-color.animacao-hover:hover.bordered ul {
  border: 5px solid #f1c40f;
}
.standard-color.animacao-hover.tabela-destaque:hover {
  margin-top: -5px;
}



/* Definição de cores para a versão PROFESSIONAL */
.professional-color .tabela-titulo,
.professional-color .alt .tabela-titulo,
.professional-color .alt-dark .tabela-titulo {
  background-color: #e67e22;
}
.professional-color .button .btn {
  background-color: #e67e22;
  border: 1px solid #e67e22;
}
.professional-color .button .btn:hover {
  background-color: #d67118;
}
.professional-color .button.btn-hover-alt .btn:hover {
  color: #e67e22;
  background-color: #ffffff;
}
.professional-color .button.colored {
  background-color: #e67e22;
}
.professional-color .button.colored:after {
  border-color: transparent;
  border-bottom-color: #e67e22;
}
.professional-color.tabela-destaque ul {
  border: 5px solid #e67e22;
}
.professional-color.tabela-destaque .tabela-mensalidade,
.professional-color.tabela-destaque .tabela-subtitulo {
  background-color: #df7619;
}
.professional-color.animacao-hover:hover {
  margin-top: -5px;
}
.professional-color.animacao-hover:hover.bordered ul {
  border: 5px solid #e67e22;
}
.professional-color.animacao-hover.tabela-destaque:hover {
  margin-top: -5px;
}



/* Definição de cores para a versão ENTERPRISE */
.enterprise-color .tabela-titulo,
.enterprise-color .alt .tabela-titulo,
.enterprise-color .alt-dark .tabela-titulo {
  background-color: #c0392b;
}
.enterprise-color .button .btn, .enterprise-color .button .btn:focus {
  background-color: #c0392b;
  border: 1px solid #c0392b;
  //outline: 0; //não permite o botão receber o fucus()
}


.enterprise-color .button .btn:hover {
  background-color: #ab3326;
}
.enterprise-color .button.btn-hover-alt .btn:hover {
  color: #c0392b;
  background-color: #ffffff;
}
.enterprise-color .button.colored {
  background-color: #c0392b;
}
.enterprise-color .button.colored:after {
  border-color: transparent;
  border-bottom-color: #c0392b;
}
.enterprise-color.tabela-destaque ul {
  border: 5px solid #c0392b;
}
.enterprise-color.tabela-destaque .tabela-mensalidade,
.enterprise-color.tabela-destaque .tabela-subtitulo {
  background-color: #b33528;
}
.enterprise-color.animacao-hover:hover {
  margin-top: -5px;
}
.enterprise-color.animacao-hover:hover.bordered ul {
  border: 5px solid #c0392b;
}
.enterprise-color.animacao-hover.tabela-destaque:hover {
  margin-top: -5px;
}
